import React from "react";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import PrivateRoute from "./components/routing/PrivateRoute";
import AuthState from "./context/auth/AuthState";
import ScreenState from "./context/screen/ScreenState";
import NewScreen from "./pages/screen/NewScreen";
import AdminPage from "./pages/admin/AdminPage";
import Login from "./pages/login/Login";
import LoginSSO from "./pages/login/LoginSSO";
import Stage from "./pages/screen/Stage";

function App() {
  return (
    <>
      <AuthState>
        <ScreenState>
          <Router>
            <Switch>
              <Route exact path="/" component={Login} />
              <Route exact path="/stage" component={Stage} />
              <Route exact path="/screens" component={NewScreen} />
              <PrivateRoute path="/admin" component={AdminPage} />
              <Route exact path="/login" component={Login} />
              <Route path="/login/:token" component={LoginSSO} />
              <Route path="*" component={Login} />
            </Switch>
          </Router>
        </ScreenState>
      </AuthState>
    </>
  );
}

export default App;
